<template>
<div class="mainform">
      <div class="mainHeader">
      条码审批
      <span @click="back" class="back">返回></span>
    </div>
  <div class="form">
    <el-form ref="form" :model="formData" class="content bascform" :rules="formRules" :class="{disabled:formDisabled}" :disabled="formDisabled" label-width="130px">
      <div v-if="fieldShow.purchaseOrderNumber!==false" class="col col4">
        <el-form-item label="采购采购单编号" prop="purchaseOrderNumber">
            <el-input v-model="formData.purchaseOrderNumber"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.lineNumber!==false" class="col col4">
        <el-form-item label="行号" prop="lineNumber">
            <el-input v-model="formData.lineNumber"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.supplierCode!==false" class="col col4">
        <el-form-item label="供应商编码" prop="supplierCode">
            <el-input v-model="formData.supplierCode"></el-input>
        </el-form-item>
      </div>
      <div v-show="fieldShow.itemNumber!==false" class="col col4">
        <el-form-item label="物料编码" prop="itemNumber">
            <el-input v-model="formData.itemNumber"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.supplier!==false" class="col col4">
        <el-form-item label="供应商名称" prop="supplier">
            <el-input v-model="formData.supplier"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.itemMessage!==false" class="col col4">
        <el-form-item label="物料描述" prop="itemMessage">
            <el-input v-model="formData.itemMessage"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.bidMinDesc!==false" class="col col4">
        <el-form-item label="申请人" prop="bidMinDesc">
            <el-input v-model="apppsn"></el-input>
        </el-form-item>
      </div>
      <div v-if="fieldShow.quantityUnit!==false" class="col col4">
        <el-form-item label="订单数量/单位" prop="quantityUnit">
            <span class="onlyText">{{formData.barcodeQuantity+'/'+formData.quantityUnit}}</span>
        </el-form-item>
      </div>
      <div v-if="fieldShow.bidMaterialMap!==false" class="col col8">
        <div class="tableList" :class="{isreadonly:formDisabled}">
            <div class="table">
              <el-table class="materTable" :data="tableData" style="width: 100%">
                  <el-table-column  prop="type"  label="条码类型" ></el-table-column>
                  <el-table-column  prop="total" label="已下发总数量"></el-table-column>
                  <el-table-column  prop="number" label="额外申请数量"></el-table-column>
                  <el-table-column  prop="reason"  label="原因"></el-table-column>
              </el-table>
            </div>
          </div>
      </div>
    </el-form>
    <approval-mind></approval-mind>
    <div class="action" v-if="haveButton">
        <el-button @click="reject" class="save" type="primary" size="medium">驳回</el-button>
        <el-button  @click="pass" class="submit" type="primary" size="medium">通过</el-button>
    </div>
  </div>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm } from '@/assets/js/http.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  name: 'BarCodeApproval',
  components: Component.components,
  data () {
    FlowCtlData.docid = ''
    return {
      formData: {},
      formRules: [],
      formDisabled: true,
      apppsn: '',
      fieldShow: {},
      haveButton: false,
      tableData: []
    }
  },
  watch: {
    $route: {
      handler () {
        this.initialize()
      }
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      FlowCtlData.flowid = 'BarCode'
      var id = this.$route.query.id
      if (id) {
        requestForm('/api/barcode/barcode/querySubOne', 'post', { id: id }).then((response) => {
          if (response.code === '200') {
            this.formData = response.data
            this.apppsn = response.data.createUserId
            FlowCtlData.docid = response.data.id
            this.tableData = [
              {
                id: 'barCode',
                type: '条码',
                total: this.formData.barcodeIssue,
                number: this.formData.barcodeAdditional,
                reason: this.formData.barcodeReason
              },
              {
                id: 'mac',
                type: 'MAC',
                total: this.formData.macBase + '/' + this.formData.macIssue,
                number: this.formData.macAdditional,
                reason: this.formData.macReason
              },
              {
                id: 'wwn',
                type: 'WWN',
                total: this.formData.wwnBase + '/' + this.formData.wwnIssue,
                number: this.formData.wwnAdditional,
                reason: this.formData.wwnReason
              }
            ]
            FlowCtlData.initialize().then(() => {
              if (FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID)) {
                this.haveButton = true
              }
            })
          }
        })
      }
    },
    back: () => {
      window.history.back()
    },
    reject () {
      FlowCtlData.getNodeAction('BH', this.formData).then(
        (val) => {
          if (val) {
            FlowCtlData.alertBHDialog(this).then(({ value }) => {
              requestForm('/api/barcode/barcode/examineBarCode', 'post', { id: this.formData.id, status: 2 }).then((response) => {
                if (response.code === '200') {
                  FlowCtlData.setNodeAction(value).then((val) => {
                    const obj = {}
                    obj.id = this.formData.id
                    obj.approvalComplete = this.formData.approvalComplete
                    FlowCtlData.setFormData(FlowCtlData, obj)
                    requestForm('/api/barcode/barcode/approvalUpdate', 'post', obj).then((response) => {
                      if (response.code === '200') {
                        this.$message({
                          message: '成功',
                          type: 'success'
                        })
                        this.back()
                      }
                    })
                  })
                }
                this.dialogShow = false
              })
            })
          }
        }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交'
        })
      })
    },
    pass () {
      FlowCtlData.getNodeAction('TJ', this.formData).then(
        (val) => {
          if (val) {
            FlowCtlData.alertDialog(this).then(() => {
              requestForm('/api/barcode/barcode/examineBarCode', 'post', { id: this.formData.id, status: 1 }).then((response) => {
                if (response.code === '200') {
                  FlowCtlData.setNodeAction().then((val) => {
                    const obj = {}
                    obj.id = this.formData.id
                    obj.approvalComplete = this.formData.approvalComplete
                    FlowCtlData.setFormData(FlowCtlData, obj)
                    requestForm('/api/barcode/barcode/approvalUpdate', 'post', obj).then((response) => {
                      if (response.code === '200') {
                        this.$message({
                          message: '成功',
                          type: 'success'
                        })
                        this.back()
                      }
                    })
                  })
                }
                this.dialogShow = false
              })
            })
          }
        }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交'
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>
@import '@/assets/css/elForm.scss';
@import '@/assets/css/tableList.scss';
  .action{
    margin-bottom: 20px;
  }
</style>
